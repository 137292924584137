import React from 'react';
import {Button, Form, Row, Col} from 'react-bootstrap';

const Filter = ({filters, onFilterChange, onSearch}) => {
    return (
        <Row className="mb-3">
            <Col>
                <Form.Control
                    type="text"
                    placeholder="Filter by name"
                    name="name"
                    value={filters.name}
                    onChange={onFilterChange}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') onSearch()
                    }}
                />
            </Col>
            <Col>
                <Button variant="primary" onClick={onSearch}>
                    Search
                </Button>
            </Col>
        </Row>
    );
};

export default Filter;
