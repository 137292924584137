import axios from "axios";

export default class Server {

    static apiBaseUrl = process.env.REACT_APP_API_BASE_URL === undefined ?
        `${window.location.protocol}//${window.location.host}` :
        process.env.REACT_APP_API_BASE_URL;

    static getAuthHeader() {
        return {Authorization: localStorage.getItem('Authorization')};
    }

    static async login(username, password) {
        const authHeader = 'Basic ' + btoa(`${username}:${password}`);
        localStorage.setItem('Authorization', authHeader);
        try {
            const response = await axios.get(`${Server.apiBaseUrl}/login`,
                {
                    headers: this.getAuthHeader()
                }
            );
            if (response.status === 200 && response.data.hasOwnProperty('role'))
                return response.data['role'];
        } catch (error) {
            console.log('Error logging in', error);
        }
        return null;
    }

    static async getDocuments(filters) {
        try {
            const response = await axios.get(`${Server.apiBaseUrl}/documents`, {
                    params: {
                        filters: filters
                    }
                }
            );
            return response.data;
        } catch (error) {
            alert(`API.getDocuments failed: ${error}`);
            throw error;
        }
    }

}