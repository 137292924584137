import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Main.css'
import MainGrid from './MainGrid';
import Filter from './Filter';
import DocEdit from './DocEdit';
import Server from "./Server";
import {Button} from "react-bootstrap";

const Main = () => {
    const [filters, setFilters] = useState({name: ''});
    const [appliesFilters, setAppliesFilters] = useState(filters);
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    const refreshData = () => {
        Server.getDocuments(appliesFilters).then(data => setData(data))
    }

    useEffect(
        refreshData,
        [appliesFilters]
    )

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearch = () => {
        setAppliesFilters(filters);
    };

    const addDocument = () => {
        setModalVisible(true);
        refreshData();
    }

    const editDocument = (id) => {
        setModalVisible(true);
        refreshData();
    }

    const deleteDocument = (id) => {
        refreshData();
    }

    return (
        <div>
            <DocEdit visible={modalVisible} setVisible={setModalVisible} />
            <h1>Filterable Table</h1>
            <Filter filters={filters} onFilterChange={handleFilterChange} onSearch={handleSearch}/>
            <Button variant="primary" onClick={addDocument}>
                Добавить
            </Button>
            <MainGrid data={data} editDocument={editDocument} deleteDocument={deleteDocument}/>
        </div>
    );
};

export default Main;