import React, {useState} from 'react';
import './LoginForm.css'
import {Button} from 'react-bootstrap';

const LoginForm = ({onLogin}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = () => {
        onLogin(username, password);
    };

    return (
        <div className={'mainContainer'}>
            <div className={'titleContainer'}>
                <div>Авторизация</div>
            </div>
            <div className={'inputContainer'}>
                <input
                    value={username}
                    type='text'
                    placeholder="Имя пользователя"
                    onChange={(ev) => setUsername(ev.target.value)}
                    className={'inputBox'}
                />
            </div>
            <div className={'inputContainer'}>
                <input
                    value={password}
                    type='password'
                    placeholder="Пароль"
                    onChange={(ev) => setPassword(ev.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') handleSubmit()
                    }}
                    className={'inputBox'}
                />
            </div>
            <div className={'inputContainer'}>
                <Button variant="primary" onClick={handleSubmit}>
                    Вход
                </Button>
            </div>
        </div>
    )
}

export default LoginForm;
