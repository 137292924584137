import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from "./LoginForm";
import Server from "./Server";
import Main from "./Main";

const App = () => {
    const [userRole, setUserRole] = useState();

    const onLogin = (username, password) => {
        Server.login(username, password).then((role) => {
            if (role == null) alert("Неправильный логин/пароль: ");
            else setUserRole(role);
        });
    }

    // Autologin for development
    useEffect(() => {
        if (process.env.REACT_APP_AUTO_LOGIN_USERNAME !== undefined) {
            onLogin(process.env.REACT_APP_AUTO_LOGIN_USERNAME, process.env.REACT_APP_AUTO_LOGIN_PASSWORD);
        }
    }, []);

    return (
        <div>
            { userRole == null ? (<LoginForm onLogin={onLogin}/>) : (<Main/>) }
        </div>
    );
};

export default App;