import React from 'react';
import './DocEdit.css'
import {Button} from "react-bootstrap";

const DocEdit = ({visible, setVisible}) => {

    return (
        <div className={visible ? 'visibleWindow' : 'hidedWindow'}>
            <div className={'modalWindowContent'}>
                Hello world!!!
                <Button variant="primary" onClick={() => setVisible(false)}>
                    Сохранить
                </Button>
                <Button variant="primary" onClick={() => setVisible(false)}>
                    Отмена
                </Button>
            </div>
        </div>
    )
}

export default DocEdit;