import React from 'react';
import {Button, Table} from 'react-bootstrap';
import './MainGrid.css'

const MainGrid = ({data, editDocument, deleteDocument}) => {
    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {data.map((item) => (
                <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td className={'container'}>
                        <div className="element">
                            <Button variant="primary" onClick={() => editDocument(item.id)}>
                                Редактировать
                            </Button>
                        </div>
                        <div className="element">
                            <Button variant="primary" onClick={() => deleteDocument(item.id)}>
                                Удалить
                            </Button>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default MainGrid;
